import ajax from '../assets/uilt/ajax';
export default {
  /**浏览一篇新闻类型（浏览次数要增加） */
  browseMessageById: (req) => { return ajax.get({ url: "/back/message/browse-message-by-id", param: req.param, success: req.success }) },
  /**查询列表 */
  getPage: (req) => { return ajax.get({ url: "/back/message/get-page", param: req.param, success: req.success }) },
  /**新增 */
  add: (req) => { return ajax.post({ url: "/back/message/add", param: req.param, success: req.success }) },
  /**更新 */
  update: (req) => { return ajax.post({ url: "/back/message/update-by-id", param: req.param, success: req.success }) },
}