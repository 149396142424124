<template>
  <el-card class="box-card full-screen notification">
    <div ref="pageHead" class="table-page-head">
      <el-form inline :model="queryForm">
        <el-form-item :label="$t('title')">
          <el-input v-model="queryForm.title" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('status')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small" style="width:150px">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('unsent')" :value="0"></el-option>
            <el-option :label="$t('sent')" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('type')">
          <el-select v-model="queryForm.type" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('general')" value="0"></el-option>
            <el-option :label="$t('urgent')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isloading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="showEditNotice()">{{$t('add')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="type" fixed="left" :label="$t('type')" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.type==0" class="text-success">{{$t('general')}}</span>
            <span v-if="scope.row.type==1" class="text-danger">{{$t('urgent')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" fixed="left" :label="$t('status')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-success">{{$t('homeVisible')}}</span>
            <span v-if="scope.row.status==1" class="text-danger">{{$t('homeInvisible')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" :label="$t('title')" width="200">
          <template slot-scope="scope">
            <div class='overstep-hidden'>{{scope.row.title}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="content" :label="$t('content')" min-width="400">
          <template slot-scope="scope">
            <div class='overstep-hidden'>{{scope.row.content}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createName" :label="$t('creatorName')" width="260">
          <template slot-scope="scope">
            <div class='overstep-hidden'>{{scope.row.createOrg||''+`(${scope.row.createName})`}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="source" :label="$t('source')" width="160">
          <template slot-scope="scope">
            <div class='overstep-hidden'>{{scope.row.source}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="browseNum" :label="$t('browseNumber')" width="160">
          <template slot-scope="scope">
            {{scope.row.browseNum||0}}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="name" label="编制人" width="160"></el-table-column>
        <el-table-column prop="userId" label="发布人工号" width="160"></el-table-column> -->
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="showEditNotice(scope.row)"> {{$t('edit')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t('editNotice')" :visible.sync="drawer" :direction="'rtl'" :modal="false" :wrapperClosable="false" :destroy-on-close="true" :size="700">
      <el-form :model="noticeForm" :rules="rules" ref="noticeForm" label-position="top">
        <el-form-item :label="$t('type')" prop="type">
          <el-radio-group size="medium" v-model="noticeForm.type">
            <el-radio v-model="noticeForm.type" label="0">{{$t('general')}}</el-radio>
            <el-radio v-model="noticeForm.type" label="1">{{$t('urgent')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('source')">
          <el-input size="medium" v-model="noticeForm.source" maxlength="250" show-word-limit />
        </el-form-item>
        <div v-if="noticeForm.id">
          <el-input type="hidden" v-model="noticeForm.id" />
          <el-form-item :label="$t('status')">
            <el-select v-model="noticeForm.status">
              <el-option :label="$t('homeVisible')" :value="0"></el-option>
              <el-option :label="$t('homeInvisible')" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item :label="$t('title')" prop="title">
          <el-input size="medium" v-model="noticeForm.title" maxlength="250" show-word-limit />
        </el-form-item>
        <el-form-item :label="$t('content')" prop="content">
          <el-input size="medium" type="textarea" v-model="noticeForm.content" :rows="10" maxlength="250" show-word-limit />
        </el-form-item>
        <el-form-item class="bottom-button">
          <el-button v-if="noticeForm.id" size="medium" type="primary" @click="edit('noticeForm')" :loading="isloading"> {{$t('submit')}} </el-button>
          <el-button v-else size="medium" type="primary" @click="add('noticeForm')" :loading="isloading"> {{$t('submit')}} </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>
<script>

import message from '@/api/message'

export default {
  name: "notificationManage",
  data () {
    return {
      queryForm: {},
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,

      //显示加载中
      isloading: false,
      //右侧弹框
      drawer: false,
      //通知表单
      noticeForm: {},
    };
  },
  computed: {
    rules () {
      return {
        type: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        title: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        content: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo }
  },
  watch: {},
  methods: {
    getList () {
      let _this = this;
      _this.isloading = true;
      let where = _this.queryForm;
      message.getPage({
        param: { current: _this.currentPage, size: _this.pageSize, where },
        success: res => {
          _this.tableData = res.data || {};
          _this.isloading = false;
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    query () { this.getList(); },
    /**新增通知 */
    add (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isloading = true;
        message.add({
          param: { source: _this.loginInfo.name + ' ' + _this.loginInfo.surname, ..._this[refName] },
          success: res => {
            if (res.code == 200) {
              _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
              _this.drawer = false;
              _this.getList();
            } else
              _this.$message({ type: "warning", message: _this.$t(res.msg) });

            _this.isloading = false;
          }
        })
      })
    },
    /**编辑通知 */
    edit (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isloading = true;
        message.update({
          param: { source: _this.loginInfo.name + ' ' + _this.loginInfo.surname, ..._this[refName] },
          success: res => {
            if (res.code == 200) {
              _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
              _this.drawer = false;
              _this.getList();
            } else
              _this.$message({ type: "warning", message: _this.$t(res.msg) });

            _this.isloading = false;
          }
        })
      })
    },
    showEditNotice (data) {
      this.drawer = true;
      this.noticeForm = data || { type: "0" };
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();
  },
}
</script>
<style  lang='less'>
</style>